:root {
    --time: 0.707s;
  }
  
  .cards {
    display: grid;
  }
  
  .card {
    perspective: 40rem;
  }
  
  .card-body {
    display: flex;
  }
  .card:hover .card-body, .card:focus .card-body {
    transform: rotateX(-3600deg);
  }
  
  .card-front, .card-back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    min-width: 100%;
  }
  
  .card-back {
    transform: rotateX(-180deg) translate(-100%, 0);
  }
  
  /* Make it Pretty */
  body {
    line-height: 1.3;
    font-family: sans-serif;
  }
  
  .cards {
    grid-gap: 1.5rem;
    grid-auto-rows: 1fr;
    padding: 3rem;
    background: whitesmoke;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }
  
  .card {
    display: flex;
    /* transition: z-index, transform calc(var(--time) / 4);
    transition-delay: var(--time), 0s; */
    text-decoration: none;
    color: inherit;
    z-index: 0;
  }
  .card:hover {
    /* transition-delay: 0s; */
    z-index: 1;
  }

  
  .card-body {
    border-radius: 0.25rem;
    flex: 1;
  }
  
  .card-front, .card-back {
    display: flex;
    align-items: center;
    background-color: white;
    box-sizing: border-box;
    padding: 1.5rem;
    border-radius: 0.25rem;
  }
  
  .card-front {
    font-size: 1.5rem;
  }
  
  .shadow, .card-front, .card-back {
    box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.04), 0 2px 2px rgba(0, 0, 0, 0.04), 0 4px 4px rgba(0, 0, 0, 0.04), 0 8px 8px rgba(0, 0, 0, 0.04), 0 16px 16px rgba(0, 0, 0, 0.04);
  }

  